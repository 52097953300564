<template>
  <v-container fluid>
    <v-row v-if="files.length">
      <v-col cols="12" :xs="colXs" :sm="colSm" :md="colMd" :lg="colLg" v-for="(file, index) in files" :key="index">
        <card-file
            :file="file"
            :uri="uri"
            :habilitar-exclusao="habilitarExclusao"
            @CardFile_remove="arquivoRemovido">
        </card-file>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!files.length" class="mb-1">
      {{ $t('label.tabela_sem_anexo') }}
    </v-row>
  </v-container>
</template>

<script>
import CardFile from './CardFile';

export default {
  name: 'ListFiles',
  components: { CardFile },
  props: {
    uri: '',
    habilitarExclusao: {
      type: Boolean,
      default: false,
    },
    colXs: {
      type: String,
      default: '6',
    },
    colSm: {
      type: String,
      default: '4',
    },
    colMd: {
      type: String,
      default: '3',
    },
    colLg: {
      type: String,
      default: '2',
    },
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    possuiArquivos(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('ListFiles_ITENS_ALTERADOS', newValue);
      }
    },
    uri() {
      this.carregarArquivos();
    },
  },
  computed: {
    possuiArquivos() {
      return !!(this.files && this.files.length);
    },
  },
  methods: {
    carregarArquivos(callback) {
      if (!this.uri.includes('null')) {
        this.$http.get(`${this.uri}/list`).then((response) => {
          this.files = response.body;
          this.$emit('ListFiles__arquivos', this.files);
          if (callback) {
            callback();
          }
        });
      }
    },
    refresh(callback) {
      this.files = [];
      setTimeout(() => this.carregarArquivos(callback), 1E2);
    },
    arquivoRemovido() {
      this.$emit('ListFiles__arquivoRemovido');
      this.refresh();
    },
  },
  mounted() {
    this.carregarArquivos();
  },
};
</script>

<style scoped>

</style>
