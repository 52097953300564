export default {
  data() {
    return {
      usuarioResources: this.$api.usuario(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'nome_usuario',
            nomCampoId: 'id_usuario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.usuarioResources.listarTodos(
                {
                  nome: autocomplete,
                  tamanhoPagina: 50000,
                  numeroPagina: 1,
                },
              ),
              itemValue: 'id',
              itemText: 'nomUsuario',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'tipo_usuario',
            nomCampoId: 'tipo_usuario',
            tipoCampo: 'LISTA',
            hintText: `${this.$tc('message.filtro_usuario_alteracao')}`,
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.funcionario', 1)}`, valor: 'FUNCIONARIO' },
                  { texto: `${this.$tc('label.fornecedor_representante_legal', 1)}`, valor: 'FORNECEDOR_REPRESENTANTE_LEGAL' },
                  { texto: `${this.$tc('label.fornecedor_master', 1)}`, valor: 'FORNECEDOR_MASTER' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            hintText: `${this.$tc('message.filtro_usuario_alteracao')}`,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'fornecedor_representante_legal_atual',
            nomCampoId: 'fornecedor_master',
            tipoCampo: 'LISTA',
            hintText: `${this.$tc('message.filtro_fornecedor_master')}`,
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.ativo', 1)}`, valor: 'ATIVO' },
                  { texto: `${this.$tc('label.inativo', 1)}`, valor: 'INATIVO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'ultima_alteracao',
            tipoCampo: 'BOOLEAN',
            valorDefault: false,
          },
          {
            labelCampo: 'tipo_alteracao',
            nomCampoId: 'tipo_alteracao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            hintText: `${this.$tc('message.filtro_usuario_alteracao')}`,
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.cadastro')}`, valor: 'USUARIO CADASTRO' },
                  { texto: `${this.$tc('label.anexo')}`, valor: 'USUARIO ANEXO' },
                  { texto: `${this.$tc('label.documento')}`, valor: 'USUARIO DOCUMENTO' },
                  { texto: `${this.$tc('label.fornecedor')}`, valor: 'USUARIO FORNECEDOR' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
        ],
      },
    };
  },
};
