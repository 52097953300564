import { render, staticRenderFns } from "./RelatorioExportacaoDetalhamentoNd.vue?vue&type=template&id=0a7f2d3e"
import script from "./RelatorioExportacaoDetalhamentoNd.vue?vue&type=script&lang=js"
export * from "./RelatorioExportacaoDetalhamentoNd.vue?vue&type=script&lang=js"
import style0 from "./RelatorioExportacaoDetalhamentoNd.vue?vue&type=style&index=0&id=0a7f2d3e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports