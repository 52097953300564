<template>
  <v-expansion-panel v-if="apuracao && apuracao.id">
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('title.anexos', 1)}}</span>
        <v-tooltip bottom v-if="habilitarUpload">
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon v-on="on" @click.stop="$refs.uploadArquivo.open()">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <list-files
        @ListFiles_ITENS_ALTERADOS="itensAlterados"
        :habilitar-exclusao="habilitarUpload"
        ref="listFiles"
        :uri="uri"
        v-if="apuracao.id"
        :col-xs="colXs"
        :col-sm="colSm"
        :col-md="colMd"
        :col-lg="colLg"
        @ListFiles__arquivos="possuiArquivos"></list-files>
    </v-expansion-panel-content>
    <upload-file-form
      v-if="habilitarUpload"
      :id="!!apuracao && apuracao.id ? apuracao.id : 100"
      :id-entidade="!!apuracao && apuracao.id ? apuracao.id : 100"
      ref="uploadArquivo"
      :title="$t('label.upload_evidencia')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import basePath from '../../common/functions/api-resource';
import ListFiles from '../../shared-components/files/ListFiles';
import UploadFileForm from '../../shared-components/upload/UploadFileForm';

export default {
  name: 'ApuracaoAcaoArquivos',
  props: {
    apuracao: Object,
    colXs: {
      type: String,
      default: '6',
    },
    colSm: {
      type: String,
      default: '4',
    },
    colMd: {
      type: String,
      default: '3',
    },
    colLg: {
      type: String,
      default: '2',
    },
  },
  data() {
    return {
      esperar: false,
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    UploadFileForm,
    ListFiles,
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.apuracao.idAcao}/apuracao/${this.apuracao.id}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.apuracao.idAcao}/apuracao/${this.apuracao.id}/upload`;
    },
    habilitarUpload() {
      if (!this.apuracao || !this.apuracao.status) {
        return false;
      }
      return (this.canEdit || this.possuiAcessoClienteParaUpload())
        && (this.apuracao.status === 'APURACAO_PREVIA' || this.apuracao.status === 'AGUARDANDO_APURACAO'
          || this.apuracao.status === 'EM_ANALISE');
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    itensAlterados(payload) {
      this.$emit('ApuracaoAcaoArquivos_itensAlterados', payload);
    },
    possuiAcessoClienteParaUpload() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CLIENTE_APU_ACAO_CRUD').length;
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      if (this.$refs.listFiles) {
        setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      }
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
      this.$emit('ApuracaoAcaoArquivos_uploadSucesso');
    },
    arquivoRemovido() {
      if (this.$refs.listFiles.files.length === 1) {
        this.$emit('ApuracaoAcaoArquivos_arquivosRemovidos');
      }
    },
    possuiArquivos(files) {
      this.$emit('ApuracaoAcaoArquivos_possuiArquivos', files);
    },
  },
  watch: {
    acao() {
      if (this.$refs.listFiles) {
        if (!this.esperar) {
          this.esperar = true;
          setTimeout(() => {
            if (this.$refs.listFiles) {
              this.$refs.listFiles.refresh();
            }
            this.esperar = false;
          }, 2E2);
        }
      }
    },
  },
};
</script>
