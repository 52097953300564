export default {
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      camposFormularioMagalu: {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_entidade.em_cadastro', 1)}`, valor: 'EM_CADASTRO' },
                  { texto: `${this.$tc('status_entidade.aguardando_aprovacao', 1)}`, valor: 'AGUARDANDO_APROVACAO' },
                  { texto: `${this.$tc('status_entidade.aprovado', 1)}`, valor: 'APROVADO' },
                  { texto: `${this.$tc('status_entidade.cancelado', 1)}`, valor: 'CANCELADO' },
                  { texto: `${this.$tc('status_entidade.reprovado', 1)}`, valor: 'REPROVADO' },
                  { texto: `${this.$tc('status_entidade.em_analise', 1)}`, valor: 'EM_ANALISE' },
                  { texto: `${this.$tc('status_entidade.encerrado', 1)}`, valor: 'ENCERRADO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource
                .buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ incluirAcoesContrato: true, autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'origem',
            nomCampoId: 'origem',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.contrato', 1)}`, valor: 'CONTRATO' },
                { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'ACAO' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'modalidade',
            nomCampoId: 'modalidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('label.faturamento_fornecedor'),
                    valor: 'FATURAMENTO_FORNECEDOR',
                  },
                  {
                    texto: this.$t('label.sellin'),
                    valor: 'SELLIN',
                  },
                  {
                    texto: this.$tc('label.sellout', 1),
                    valor: 'SELLOUT',
                  },
                  {
                    texto: this.$tc('label.valor_fixo', 1),
                    valor: 'VALOR_FIXO',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
        ],
      },
      camposFormularioKabum: {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_entidade.em_cadastro', 1)}`, valor: 'EM_CADASTRO' },
                  { texto: `${this.$tc('status_entidade.aguardando_aprovacao', 1)}`, valor: 'AGUARDANDO_APROVACAO' },
                  { texto: `${this.$tc('status_entidade.aprovado', 1)}`, valor: 'APROVADO' },
                  { texto: `${this.$tc('status_entidade.cancelado', 1)}`, valor: 'CANCELADO' },
                  { texto: `${this.$tc('status_entidade.reprovado', 1)}`, valor: 'REPROVADO' },
                  { texto: `${this.$tc('status_entidade.em_analise', 1)}`, valor: 'EM_ANALISE' },
                  { texto: `${this.$tc('status_entidade.encerrado', 1)}`, valor: 'ENCERRADO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource
                .buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ incluirAcoesContrato: true, autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
