import { render, staticRenderFns } from "./DashboardModalPendencias.vue?vue&type=template&id=3746e330"
import script from "./DashboardModalPendencias.vue?vue&type=script&lang=js"
export * from "./DashboardModalPendencias.vue?vue&type=script&lang=js"
import style0 from "./DashboardModalPendencias.vue?vue&type=style&index=0&id=3746e330&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports